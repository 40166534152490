import { jwtDecode } from 'jwt-decode';

export function getRoles() {
    if (localStorage.getItem("btkn") != null) {
        return jwtDecode(localStorage.getItem("btkn")).roles;
    } else {
        return null;
    }
}

export function roleAdmin(): boolean {
    let userRoles = getRoles();
    if (userRoles != undefined && userRoles.indexOf("Admin") > -1) {
        return true;
    } else if (userRoles != undefined && userRoles.indexOf("Dev") > -1) {
        return true;
    } else {
        return false;
    }
}

export function roleDev(): boolean {
    let userRoles = getRoles();
    if (roleAdmin()) {
        return true;
    } else if (userRoles != undefined && userRoles.indexOf("Dev") > -1) {
        return true;
    } else {
        return false;
    }
}

export function roleSeniorTech(): boolean {
    let userRoles = getRoles();
    if (roleAdmin()) {
        return true;
    } else if (userRoles != undefined && userRoles.indexOf("SrTechnician") > -1) {
        return true;
    } else {
        return false;
    }
}

export function roleQAManager(): boolean {
    let userRoles = getRoles();
    if (userRoles != undefined && userRoles.indexOf("QAManager") > -1) {
        return true;
    } else {
        return false;
    }
}

export function roleProductManager(): boolean {
    let userRoles = getRoles();
    if (userRoles != undefined && userRoles.indexOf("ProductManager") > -1) {
        return true;
    } else {
        return false;
    }
}

export function roleQAController(): boolean {
    let userRoles = getRoles();
    if (userRoles != undefined && userRoles.indexOf("QAController") > -1) {
        return true;
    } else {
        return false;
    }
}

export function roleSE04AuthorizedInspector(): boolean {
    let userRoles = getRoles();
    if (userRoles != undefined && userRoles.indexOf("SE04AuthorizedInspector") > -1) {
        return true;
    } else {
        return false;
    }
}

export function roleSE04CertSigningAuthority(): boolean {
    let userRoles = getRoles();
    if (userRoles != undefined && userRoles.indexOf("SE04CertSigningAuthority") > -1) {
        return true;
    } else {
        return false;
    }
}