import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { useAuthFetch } from '../useAuthFetch';
import { Link } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import { SideBarDef, } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import Button from 'react-bootstrap/Button';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { requestProductFamilyData } from "../hooks/ProductFamily";
import { getProductGroupByProductFamily } from "../hooks/Product";
import { requestTemplateCheckedData } from "../hooks/Template";

import { LoadingTemplate } from '../LoadingTemplate';
import { CreateProductDialog } from '../CreateProductDialog';
import "../assets/css/formItems.css";

export function ProductsPage() {
    const authFetch = useAuthFetch();
    const gridRef = useRef<AgGridReact>(null);

    const [ loading, setLoading ] = useState(true); 
    const [ productData, setProductData ] = useState([]);
    const [ productFamiliesData, setProductFamiliesData] = useState([]);
    const [ templateItemsData, setTemplateItemsData] = useState([]);
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [dialogInitialData, setDialogInitialData] = useState(null);
    const [productSuccess, setProductSuccess] = useState<string | null>(null);

    const requestData = async () => {
        await requestProductFamilyData(authFetch).then(res => { return res; }).then((data) => { setProductFamiliesData(data); });
        await requestTemplateCheckedData(authFetch, true).then(res => { return res; }).then((data) => {
            const sorted = data.sort((a, b) => { return a.name > b.name ? 1 : -1 })
                .map(({ id, name }) => { return { id, name } })
            setTemplateItemsData(sorted);
        });
        await getProductGroupByProductFamily(authFetch).then(res => { return res; }).then((data) => { setProductData(data); });
        setLoading(false);
    }

    useEffect(()=> { requestData() }, []);

    const handleDialogOpen = (initData = null) => {
        setDialogInitialData(initData);
        setDialogOpen(true); 
    }

    const handleDialogSubmit = (status: string) => {
        if(status != "cancel") {
            requestData(); 
        }
        setDialogOpen(false);
    }

    const ProductFamilyCell = (props) => {
        if (props != null && props.data != null) {
            return props.data.productFamilyName;
        }
    };

    const CustomButtonComponent = (props) => {
        if (props !=null && props.data !=null) {
            return (
                <div style={{ marginLeft: "-10px" }}>
                <Button type="button" className="btn btn-primary btn-sm" onClick={(ev) => {
                    ev.stopPropagation();
                    handleDialogOpen({ ...props.data });
                }}>
                    Edit
                    &nbsp;&nbsp;<i className="fa fa-edit" />
                </Button>
            </div>
            )
        }
    };

    const [colDefs, setColDefs] = useState([
        { field: "assetType", maxWidth: 150 },
        { field: "productFamily", cellRenderer: ProductFamilyCell },
        { field: "productCode" },
        { field: "manufacturer" },
        { field: "model" },
        { headerName: "Commissioning", field: "commissioning", maxWidth: 160 },
        { field: "defaultTemplate" },
        { field: "Actions", cellRenderer: CustomButtonComponent }
    ]);

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current!.api.setGridOption(
            "quickFilterText",
            (document.getElementById("filter-text-box") as HTMLInputElement).value,
        );
    }, []);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agFilteredRowCountComponent', align: 'left' }
            ]
        };
    }, []);

    const sideBar = useMemo<
        SideBarDef | string | string[] | boolean | null
    >(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
            defaultToolPanel: "",
        };
    }, []);

    const productAdded = (value: string) => { toast(value); };

    useEffect(() => { productAdded(productSuccess); }, [productSuccess]);

    const onCsvExport = useCallback(() => {
        gridRef.current!.api.exportDataAsCsv();
    }, []);

    const onExcelExport = useCallback(() => {
        gridRef.current!.api.exportDataAsExcel();
    }, []);

    return (
        <>
            <ToastContainer />
            <LoadingTemplate isLoading={loading}>
                <div>
                    <Button variant="primary" className="rounded-circle modalAddButton" onClick={handleDialogOpen}>+</Button>

                    <div className="titleRow">
                        <div>Products</div>
                    </div>
                    <Link to={"../ProductFamily"}>ProductFamily </Link>
                    <div className="row mb-2">
                        <div className="col col-8 col-lg-3 col-sm-6">
                            <input type="text" id="filter-text-box" className="form-control" placeholder="Filter..." onInput={onFilterTextBoxChanged} />
                        </div>
                        <div style={{ float: "left", width: "8%" }} >
                            <i className="fa-solid fa-file-excel fa-2x me-2 clickable hoverable" onClick={onExcelExport} style={{ lineHeight: '1.25em' }}></i>
                            <i className="fa-solid fa-file-csv fa-2x clickable hoverable" onClick={onCsvExport}></i>
                        </div>
                    </div>

                    <div className="ag-theme-quartz" style={{ height: 600 }}>
                        <AgGridReact ref={gridRef} rowData={productData} columnDefs={colDefs} sideBar={sideBar} statusBar={statusBar} />
                    </div>

                    <CreateProductDialog isOpen={dialogOpen} onSubmit={handleDialogSubmit} initialData={dialogInitialData}
                        productFamilies={productFamiliesData} defaultTemplates={templateItemsData} productSuccess={(successStatus) => setProductSuccess(successStatus)} />
            
                </div>
            </LoadingTemplate>
        </>
    );
};

