import { useState } from "react";
import { useAuthFetch } from '.././useAuthFetch'
import { getApiUrl } from '.././authConfig';

export async function requestAppInfo(authFetch: any) {
    let fetchResponse = await authFetch('GET', getApiUrl(`info`))
    if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
        let responseData;
        try {
            responseData = await fetchResponse.json();
        } catch (error) {
            console.log('Request AppInfo Error: ' + error);
        } finally {
            return responseData;
        }
    }
}