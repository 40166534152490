import { useState } from "react";
import { useAuthFetch } from '.././useAuthFetch'
import { getApiUrl } from '.././authConfig';

export async function requestUserGridConfigs(authFetch: any, name: string) {
    let fetchResponse = await authFetch('GET', getApiUrl(`usergridconfigs?gridName=${name}`))
    if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
        let responseData;
        try {
            responseData = await fetchResponse.json();
        } catch (error) {
            console.log('Request UserGridConfig Error: ' + error);
        } finally {
            return responseData;
        }
    }
}

export async function requestUserGridConfigsUpdateDefault(authFetch: any, gridConfigId: string, isDefault: boolean) {
    let fetchResponse = await authFetch('PUT', getApiUrl(`usergridconfigs/${gridConfigId}`), { IsDefault: isDefault })
    if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
        let responseData;
        try {
            responseData = await fetchResponse.json();
        } catch (error) {
            console.log('Request UserGridConfig Default Update Error: ' + error);
        } finally {
            return responseData;
        }
    }
}

export async function deleteUserGridConfig(authFetch: any, gridConfigId: string) {
    let fetchResponse = await authFetch('DELETE', getApiUrl(`usergridconfigs/${gridConfigId}`))
    if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
        let responseData;
        try {
            responseData = await fetchResponse.json();
        } catch (error) {
            console.log('Delete UserGridConfig Error: ' + error);
        } finally {
            return responseData;
        }
    }
}