import React, { useEffect, useState } from "react";
import { getApiUrl } from "./authConfig";
import { useAuthFetch }  from './useAuthFetch';
import { observer, useLocalObservable } from 'mobx-react';
import { toJS } from 'mobx';
import moment from "moment";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

import Multiselect from 'multiselect-react-dropdown';

import "./assets/css/modal.css";

import { requestTestBenchData } from "./hooks/TestBench";

import { SealingInfoObject } from "./common/types";
import { TestBenchObject } from "./common/types";
import { AssetObject } from "./common/types";

import { MeterConfigurationDrop } from "./common/typeOptions";
import { InspectionTypeDrop } from "./common/typeOptions";
import { MeterVoltageDrop } from "./common/typeOptions";
import { MeterCurrentDrop } from "./common/typeOptions";
import { DemandTypeDrop } from "./common/typeOptions";
import { PulseOutputTypeDrop } from "./common/typeOptions";
import { NumberElementsDrop } from "./common/typeOptions";
import { SealParametersDrop } from "./common/typeOptions";

export type CreateSealingInfoDialogProps = {
    isOpen?: boolean
    onSubmit?: (status: string)=>void
    initialData?: SealingInfoObject
    assetData?: AssetObject
} 

export const CreateSealingInfoDialog = observer((props: CreateSealingInfoDialogProps) => {
    const authFetch = useAuthFetch(); 
    const [modalOpen, setModalOpen] = useState(false);
    const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
    const [clipboardStatus, setClipboardStatus] = useState("");
    const [testBenchData, setTestBenchData] = useState<TestBenchObject[]>([]);
    const [sealInfoData, setSealInfoData] = useState<SealingInfoObject>();

    let isOpen = props.isOpen || false;

    const handleModalSuccessClose = () => { setClipboardStatus(""); setModalSuccessOpen(false); handleClose() };
    
    var initialAssetData = props.assetData || {
        id: "",
        model: "",
        manufacturer: "",
        serialNumber: "",
    }

    const assetData = useLocalObservable(() => ({ 
        id: "",
        model: "",
        manufacturer: "",
        serialNumber: "",
    }))

    const setInitialData = () => {
        assetData.id = initialAssetData.id;
        assetData.model = initialAssetData.model;
        assetData.manufacturer = initialAssetData.manufacturer;
        assetData.serialNumber = initialAssetData.serialNumber;

        setSealInfoData(props.initialData);
    }

    const dateFromDateString = (dateString: string) => {
        var date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        return date;
    };

    const dateForPicker = (dateString: string) => {
        return moment(new Date(dateString)).format('YYYY-MM-DD');
    };

    useEffect(() => { setInitialData() }, [props.initialData]);

    useEffect(() => {
        requestTestBenchData(authFetch).then(res => { return res; }).then((data) => { setTestBenchData(data); });
    }, []);

    const handleClose = () => {
        if (props.onSubmit != null) {
            setClipboardStatus("");
            props.onSubmit("cancel");
        }
    };

    const clipboardSealingInfo = () => {
        setClipboardStatus("");
        navigator.clipboard.readText().then((clipText) => {
            try {
                let sealInfoObj = JSON.parse(clipText);
                setSealInfoData(sealInfoObj);
            } catch (e) {
                setClipboardStatus("ERROR: Invalid Json");
            }
        });
    }

    const handleSubmit = async () => {
        sealInfoData.assetId = assetData.id;
        let data = toJS(sealInfoData);

        let url = getApiUrl("sealingInfo");

        let fetchResponse = await authFetch("POST", url, {...data})

        if(fetchResponse.status != 409) {
            if(props.onSubmit != null) {
                props.onSubmit("success")
            }
        } else {
            setModalOpen(true)
        }
    }

    const handleMultiChange = (event) => {
        sealInfoData.sealParameters = [];
        event.map(item => (
            sealInfoData.sealParameters.push(item.name)
        ));
    }

    function handleMultiSelect (event) {
        let sealParamArray = [];
        let sealParam = {};

        if (event != null) {
            event.map(item => (
                sealParam = { name: item },
                sealParamArray.push(sealParam)
            ));
        }
        return sealParamArray;
    }

    return (
        <Modal show={isOpen} size="xl">
            <Modal.Header>
                <Modal.Title>Create Sealing Info</Modal.Title>
                <div className="text-end" style={{width: "78%"}}>
                    <Button className="btn btn-primary float-right" onClick={clipboardSealingInfo}>Paste Clipboard</Button>
                    <div style={{color: "red"}}>{clipboardStatus}</div>
                </div>
            </Modal.Header>

            <Modal.Body>

                {<div className="dropcontainer" style={{ marginBottom:"40px" }}>
                    <div className="d-grid gap-2">
                        <div className="dropdown-label">Test Bench</div>
                        <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={sealInfoData?.testBenchId == null || testBenchData.find(it => it.id == sealInfoData?.testBenchId) == null ? "" : testBenchData.find(it => it.id == sealInfoData?.testBenchId).name} >
                            {testBenchData.map((data) => {
                                return (
                                    <Dropdown.Item onClick={() => { setSealInfoData({ ...sealInfoData, testBenchId: data.id })}} key={data.id}>{data.name}</Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </div>
                </div>}

                <div style={{ width: "33%", float: "left", display: "table", padding: "5px" }}>

                    <div style={{ width: "100%", color: "red", textAlign: "center" }}>MAIN</div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">QMC WO#</div>
                            <Form.Control className="modal-input" size="lg" type="text" value={sealInfoData?.workOrder} defaultValue={sealInfoData?.workOrder} onChange={e => setSealInfoData({ ...sealInfoData, workOrder: e.target.value })} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Meter MAC Address</div>
                            <Form.Control className="modal-input" size="lg" type="text" value={sealInfoData?.macAddress} defaultValue={sealInfoData?.macAddress} onChange={e => setSealInfoData({ ...sealInfoData, macAddress: e.target.value })} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Firmware</div>
                            <Form.Control className="modal-input" size="lg" type="text" value={sealInfoData?.meterFirmware} defaultValue={sealInfoData?.meterFirmware} onChange={e => setSealInfoData({ ...sealInfoData, meterFirmware: e.target.value })} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Element Configuration</div>
                            <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={sealInfoData?.meterConfiguration == null || MeterConfigurationDrop.find(it => it.value == sealInfoData.meterConfiguration) == null ? "" : MeterConfigurationDrop.find(it => it.value == sealInfoData.meterConfiguration).name}>
                                {MeterConfigurationDrop.map((data) => {
                                    return (
                                        <Dropdown.Item onClick={() => { setSealInfoData({ ...sealInfoData, meterConfiguration: data.value })}} key={data.value}>{data.name}</Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Number of Elements</div>
                            <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={sealInfoData?.elements == null || NumberElementsDrop.find(it => it == sealInfoData.elements) == null ? "" : NumberElementsDrop.find(it => it == sealInfoData.elements)}>
                                {NumberElementsDrop.map((data) => {
                                    return (
                                        <Dropdown.Item onClick={() => { setSealInfoData({ ...sealInfoData, elements: data }) }} key={data}>{data}</Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Meter Points</div>
                            <Form.Control className="modal-input" size="lg" type="number" max={999} value={sealInfoData?.numMeteringPoints} defaultValue={sealInfoData?.numMeteringPoints} onKeyPress={(e) => { sealInfoData?.numMeteringPoints.length === 3 && e.preventDefault(); }} onChange={e => setSealInfoData({ ...sealInfoData, numMeteringPoints: e.target.value })} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Voltage (V)</div>
                            <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={sealInfoData?.meterVoltage == null || MeterVoltageDrop.find(it => it == sealInfoData.meterVoltage) == null ? "" : MeterVoltageDrop.find(it => it == sealInfoData.meterVoltage)}>
                                {MeterVoltageDrop.map((data) => {
                                    return (
                                        <Dropdown.Item onClick={() => { setSealInfoData({ ...sealInfoData, meterVoltage: data })}} key={data}>{data}</Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Current (A)</div>
                            <Form.Control className="modal-input" size="lg" type="text" value={sealInfoData?.meterCurrent} defaultValue={sealInfoData?.meterCurrent} onChange={e => setSealInfoData({ ...sealInfoData, meterCurrent: e.target.value })} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Multiplier(s)s</div>
                            <Form.Control className="modal-input" size="lg" type="number" value={sealInfoData?.multiplier} defaultValue={sealInfoData?.multiplier} onChange={e => setSealInfoData({ ...sealInfoData, multiplier: e.target.value })} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">PT S/N</div>
                            <Form.Control className="modal-input" size="lg" type="text" value={sealInfoData?.ptSerialNumber} defaultValue={sealInfoData?.ptSerialNumber} onChange={e => setSealInfoData({ ...sealInfoData, ptSerialNumber: e.target.value })} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Additional Information</div>
                            <Form.Control className="modal-input" size="lg" type="text" value={sealInfoData?.notes} defaultValue={sealInfoData?.notes} onChange={e => setSealInfoData({ ...sealInfoData, notes: e.target.value })} />
                        </div>
                    </div>

                </div>



                <div style={{ width: "33%", float: "left", display: "table", padding: "5px" }}>

                    <div style={{ width: "100%", color: "red", textAlign: "center" }}>Meter Owner Info</div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Certificate Number</div>
                            <Form.Control className="modal-input" size="lg" type="text" value={sealInfoData?.certificateNumber} defaultValue={sealInfoData?.certificateNumber} onChange={e => setSealInfoData({ ...sealInfoData, certificateNumber: e.target.value })} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Revision</div>
                            <Form.Control className="modal-input" size="lg" type="text" value={sealInfoData?.certificateNumberVersion} defaultValue={sealInfoData?.certificateNumberVersion} onChange={e => setSealInfoData({ ...sealInfoData, certificateNumberVersion: e.target.value })} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Meter Owner</div>
                            <Form.Control className="modal-input" size="lg" type="text" value={sealInfoData?.meterOwnerName} defaultValue={sealInfoData?.meterOwnerName} onChange={e => setSealInfoData({...sealInfoData, meterOwnerName: e.target.value})} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Meter Owner Address</div>
                            <Form.Control className="modal-input" size="lg" type="text" value={sealInfoData?.meterOwnerAddress} defaultValue={sealInfoData?.meterOwnerAddress} onChange={e => setSealInfoData({ ...sealInfoData, meterOwnerAddress: e.target.value })} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Contractor Reg. No.</div>
                            <Form.Control className="modal-input" size="lg" type="number" defaultValue={sealInfoData?.contractorNumber} onKeyPress={(e) => { sealInfoData?.contractorNumber.length === 6 && e.preventDefault(); }} max={999999} onChange={e => sealInfoData.contractorNumber = e.target.value} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Contractor Inspection #</div>
                            <Form.Control className="modal-input" size="lg" type="text" value={sealInfoData?.inspectionNumber} defaultValue={sealInfoData?.inspectionNumber} onChange={e => setSealInfoData({ ...sealInfoData, inspectionNumber: e.target.value })} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Conformance Status</div>
                            <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={sealInfoData?.accepted == null || [true, false].find(it => it == sealInfoData?.accepted) == null ? "" : [true, false].find(it => it == sealInfoData?.accepted).toString()}>
                                {[true, false].map((data) => {
                                    return (
                                        <Dropdown.Item onClick={() => { setSealInfoData({ ...sealInfoData, accepted: data })}} key={data.toString()}>{data.toString()}</Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Inspection date</div>
                            <Form.Control type="date" size="lg" name="inspectionDate" value={sealInfoData?.inspectedDate == null ? dateForPicker(Date()) : dateForPicker(sealInfoData.inspectedDate.toString())} onChange={e => setSealInfoData({ ...sealInfoData, inspectedDate: dateFromDateString(e.target.value) })} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Inspected by</div>
                            <Form.Control className="modal-input" size="lg" type="text" value={sealInfoData?.inspectedBy} defaultValue={sealInfoData?.inspectedBy} onChange={e => setSealInfoData({ ...sealInfoData, inspectedBy: e.target.value })} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Reviewed by</div>
                            <Form.Control className="modal-input" size="lg" type="text" value={sealInfoData?.reviewedBy} defaultValue={sealInfoData?.reviewedBy} onChange={e => setSealInfoData({ ...sealInfoData, reviewedBy: e.target.value })} />
                        </div>
                    </div>
                </div>


                <div style={{ width: "33%", display: "table", padding: "5px" }}>

                    <div style={{ width: "100%", color: "red", textAlign: "center" }}>Regulatory</div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Meter Type</div>
                            <Form.Control className="modal-input" size="lg" type="text" value={sealInfoData?.mcMeterType} defaultValue={sealInfoData?.mcMeterType} onChange={e => setSealInfoData({ ...sealInfoData, mcMeterType: e.target.value })} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Inspection Type</div>
                            <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={sealInfoData?.inspectionType == null || InspectionTypeDrop.find(it => it.value == sealInfoData?.inspectionType) == null ? "" : InspectionTypeDrop.find(it => it.value == sealInfoData?.inspectionType).name}>
                                {InspectionTypeDrop.map((data) => {
                                    return (
                                        <Dropdown.Item onClick={() => { setSealInfoData({ ...sealInfoData, inspectionType: data.value })}} key={data.value}>{data.name}</Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Notice of Approval</div>
                            <Form.Control className="modal-input" size="lg" type="text" value={sealInfoData?.approvalNumber} defaultValue={sealInfoData?.approvalNumber} onChange={e => setSealInfoData({ ...sealInfoData, approvalNumber: e.target.value })} />
                        </div>
                    </div>

                    {/*<div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Billing Parameters2</div>
                            <Select
                                isMulti
                                name="billingParams"
                                options={options2}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={event => handleMultiChange(event)}
                            />
                        </div>
                    </div>*/}



                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Billing Parameters</div>
                            <Multiselect options={SealParametersDrop} displayValue="name" selectedValues={handleMultiSelect(sealInfoData?.sealParameters)} onSelect={event => handleMultiChange(event)} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Demand type</div>
                            <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={sealInfoData?.demandType == null || DemandTypeDrop.find(it => it == sealInfoData?.demandType) == null ? "" : DemandTypeDrop.find(it => it == sealInfoData?.demandType)}>
                                {DemandTypeDrop.map((data) => {
                                    return (
                                        <Dropdown.Item onClick={() => { setSealInfoData({ ...sealInfoData, demandType: data })}} key={data}>{data}</Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Intervals (min)</div>
                            <Form.Control className="modal-input" size="lg" type="number" value={sealInfoData?.demandInterval} defaultValue={sealInfoData?.demandInterval} onChange={e => setSealInfoData({ ...sealInfoData, demandInterval: e.target.value })} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Pulse Type/Form</div>
                            <DropdownButton className={"d-grid"} size="lg" style={{ width: '100%' }} variant='outline-dark' title={sealInfoData?.pulseOutputType == null || PulseOutputTypeDrop.find(it => it == sealInfoData?.pulseOutputType) == null ? "" : PulseOutputTypeDrop.find(it => it == sealInfoData?.pulseOutputType)}>
                                {PulseOutputTypeDrop.map((data) => {
                                    return (
                                        <Dropdown.Item onClick={() => { setSealInfoData({ ...sealInfoData, pulseOutputType: data })}} key={data}>{data}</Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Parameters altered</div>
                            <Form.Control className="modal-input" size="lg" type="text" value={sealInfoData?.parametersAltered} defaultValue={sealInfoData?.parametersAltered} onChange={e => setSealInfoData({ ...sealInfoData, parametersAltered: e.target.value })} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Sealing date</div>
                            {/*<Form.Control type="date" size="lg" name="inspectionDate" value={sealInfoData?.sealDate == null ? dateForPicker(Date()) : dateForPicker(sealInfoData.sealDate)} onChange={e => sealInfoData.sealDate = dateFromDateString(e.target.value)} />*/}
                            <Form.Control type="date" size="lg" name="inspectionDate" value={sealInfoData?.sealDate == null ? dateForPicker(Date()) : dateForPicker(sealInfoData.sealDate.toString())} onChange={e => setSealInfoData({ ...sealInfoData, sealDate: dateFromDateString(e.target.value)})} />
                        </div>
                    </div>

                    <div className="dropcontainer">
                        <div className="d-grid gap-2">
                            <div className="dropdown-label">Due for reverification (year)</div>
                            <Form.Control className="modal-input" size="lg" type="number" defaultValue={sealInfoData?.sealExpiryYear} onChange={e => sealInfoData.sealExpiryYear = e.target.value} />
                        </div>
                    </div>

                </div>

            </Modal.Body>
            <Modal.Footer>
                <button onClick={handleClose} className="btn btn-light">Cancel</button>
                <button onClick={handleSubmit} className="btn btn-light">Submit</button>
            </Modal.Footer>

            <Modal className="modalStyle" show={modalSuccessOpen} centered>
                <Modal.Header>
                    <Modal.Title>Sealing Info Submit</Modal.Title>
                </Modal.Header>
                <Modal.Body>The Sealing Info has been added, Click to close.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalSuccessClose}>Close</Button>
                </Modal.Footer>
            </Modal>

        </Modal>
    );
});
