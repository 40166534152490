import { useState } from "react";
import { useAuthFetch } from '.././useAuthFetch'
import { getApiUrl } from '.././authConfig';

export async function requestReports(authFetch: any, building_id: string, asset_Ids: string) {
    let fetchResponse = await authFetch('POST', getApiUrl(`reports/SMCReport`), {
        buildingId: building_id, assetIds: asset_Ids
    })
    if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
        let responseData;
        try {
            responseData = await fetchResponse;
        } catch (error) {
            console.log('Request Reports Error: ' + error);
        } finally {
            const header = responseData.headers.get('Content-Disposition');
            const parts = header.split(';');
            var fileName = parts[1].split('=')[1].replaceAll("\"", "");
            return responseData.blob().then(blob => { download(blob, fileName) });
        }
    }
}

function download(blob, filename: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}